export default async () => {
    try {
        return await useFetchWithCache('/api/center/centers', true);
    } catch (error) {
        // throw error not working here...
        throw showError({
            statusCode: 404,
            name: 'NotFoundError',
            message: error,
            statusMessage: 'Error',
            fatal: true
        })
    }
}
