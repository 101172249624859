<script setup>
import { storeToRefs } from 'pinia';
import { useSideBar } from '~/stores/sideBar';
import { useAuthStore } from '~/stores/auth';
import { useMessageStore } from '~/stores/messages';
import { trackPageLoad, trackUserClick } from '~/composables/useTracking';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Fetch centers
const { cached: centers, isPending } = await useFetchCenterList();

// Load user data
const authStore = useAuthStore();
const { handleUserData } = authStore;
const { loggedIn } = storeToRefs(authStore);

// Get sideBarStatus + toggle function
const sideBarStore = useSideBar();
const { toggleSideBar, toggleSideBarOverlay } = sideBarStore;

// Tracking
trackPageLoad('Center_Selection');

/**
 * Selects a center by its short name and center name.
 *
 * @param {string} shortName - The short name of the center.
 * @param {string} centerName - The name of the center.
 * @return {Promise<void>} - A promise that resolves when the center is selected.
 */
const selectCenter = async (shortName, centerName) => {
    // Track center selection
    trackUserClick('Center_Select', {
        center_id: shortName?.toUpperCase(),
        center_name: centerName,
        center_preference: shortName?.toUpperCase()
    });

    // Close Sidebar
    toggleSideBar({ open: false })

    // Open sidebar with center details
    toggleSideBarOverlay({
        open: true,
        content: 'centerDetail'
    })

    // Navigate to center
    await navigateTo(`/${shortName}`);

    // Update user profile with new center selection
    if (loggedIn.value) {
        await handleUserData({
            method: 'PATCH',
            payload: {
                centerCode: shortName
            }
        });
    }
};
</script>

<template>
    <SideBarWrapper class="side-bar-content-center-selector">
        <SideBarHeader>
            <SideBarMainHeadline :text="staticKeys?.centerselector_mainheadline" />
            <p>{{ staticKeys?.centerselector_text }}</p>
        </SideBarHeader>

        <LoadingSpinner v-if="isPending" class="sidebar-loader" />

        <ul v-else class="center-list">
            <CenterListItem
                v-for="(center, index) in centers"
                :key="`center-id-${index}`"
                :center="center"
                data-vitest="center-list-item"
                @click="selectCenter(center?.uid, center?.name)"
            />
        </ul>

        <DevOnly>
            <pre>{{ centers }}</pre>
        </DevOnly>
    </SideBarWrapper>
</template>

<style lang="scss">
.side-bar-content-center-selector {
    height: 100%;

    .side-bar-header {
        .sidebar-main-headline {
            margin-block-end: px2rem(30);
        }

        > p {
            @include body2-medium;

            text-align: center;
            margin: 0 0 70px;
        }
    }
}

.center-list {
    margin: 0;
    padding: 0 0 40px;
    list-style-type: none;
}
</style>
